<template>
  <v-container
    fluid
    class="deep-orange lighten-5"
    :style="{
      height: '100%',
      backgroundImage: 'url(' + require('@/assets/material2.jpg') + ')',
      backgroundSize: 'cover',
    }"
  >
    <v-row align="center" justify="center"
      ><v-card class="mt-6" max-width="280"
        ><v-img contain :src="require('@/assets/code.png')"></v-img
        ><v-form ref="form" @submit.prevent="loginAuth"
          ><v-card-text>
            <!-- {{ login }} -->
            <v-autocomplete
              label="医疗机构名称"
              v-model="login.username"
              :items="items"
              item-text="username"
              :loading="loading"
              :rules="[(v) => !!v || '此项必填']"
            ></v-autocomplete
            ><v-text-field
              label="密码"
              type="password"
              v-model="login.password"
              persistent-hint
              hint="如果您忘记了密码，请扫描上方二维码，加群联系管理员。"
              :rules="[(v) => !!v || '此项必填']"
          /></v-card-text>
          <v-card-actions
            ><v-btn text :to="{ name: 'Register' }">立即注册</v-btn><v-spacer />
            <v-btn color="primary" type="submit">登录</v-btn></v-card-actions
          ></v-form
        ></v-card
      >
    </v-row></v-container
  >
</template>
<script>
export default {
  data: () => ({ items: [], login: { username: "", password: "" }, loading: false }),
  methods: {
    loginAuth() {
      if (this.$refs.form.validate()) {
        let params = {
          login_username: this.login.username,
          login_password: this.login.password,
        };
        let that = this;
        this.axios
          .post("login", params)
          .then((res) => {
            // if (res.code == 0) {
            // that.MSG.success(res.msg);
            that.loginSuccess(res);
            // } else {
            // that.MSG.warning(res.msg);
            // }
          })
          .catch((err) => {
            if (err.message === "Network Error") {
              alert("密码错误");
            }
          });
      }
    },
    loginSuccess(result) {
      sessionStorage.setItem("token", result.data);
      this.$router.push({ name: "Home" });
    },
  },
  created() {
    this.loading = true;
  },
  mounted() {
    this.axios.get("/organizations").then((e) => {
      this.loading = false;
      this.items = e.data;
    });
  },
};
</script>
